import React from 'react'
import Helmet from 'react-helmet'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";

class CollectionRoute extends React.Component {
  render() {
    const posts = this.props.data.allMarkdownRemark.edges
    const collection = this.props.pageContext.collection
    const title = this.props.data.site.siteMetadata.title
    const collectionHeader = `${collection}`
    const colorClass = collectionHeader === 'Relentless' ? 'color-pink' : 'color-purple';
    const headerClass = `has-text-centered is-size-2 py-6 ${colorClass}`;

    return (
      <Layout>
        <div className={headerClass}>{collectionHeader}</div>
        <section className="section">
          <Helmet title={`${collectionHeader} | ${title}`} />
          <div className="container content">
            <div className="columns" style={{ marginBottom: '6rem' }}>
                <div className="container content" style={{paddingTop: 60}}>
                  <div className="columns is-multiline is-centered">
                    {posts && posts.map(({ node: painting }) => (
                      <div className="is-parent column is-4" key={painting.fields.slug}>
                        <article>
                          <div className="featured-thumbnail">
                            <Link to={painting.fields.slug}>
                              <PreviewCompatibleImage
                                imageInfo={{
                                  image: painting.frontmatter.featuredimage,
                                  alt: painting.frontmatter.title,
                                }}
                              />
                            </Link>
                          </div>
                        </article>
                      </div>

                    ))}
                  </div>
                </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default CollectionRoute

export const tagPageQuery = graphql`
  query CollectionPage($collection: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { order: DESC, fields: [frontmatter___order, frontmatter___date] }
      filter: { frontmatter: { collections: { in: [$collection] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            featuredimage {
              childImageSharp {
                fixed(width: 350, height: 350, quality: 100) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  }
`
